<template>
  <div class="container products-list-page">
    <div class="row products-list-page-row">
      <div class="col-12 products-list-page-wrapper">
        <product-tabs :tabs="tabs">
          <template slot="tabcontent">
            <div
              id="snacks"
              class="tab-pane fade show active snack-product-list-page"
              aria-controls="snacks"
              role="tabpanel"
            >
              <div class="row snack-product-list-page-row">
                <div
                  v-for="(snack, index) in snacks"
                  :key="index"
                  class="col-12 col-sm-6 col-lg-4 col-xl-3 snack-product-list-page-content"
                >
                  <product
                    :product-detail="snack"
                  />
                </div>
              </div>
            </div>

            <div
              id="sandwich"
              class="tab-pane fade sandwich-product-list-page"
              aria-controls="sandwich"
              role="tabpanel"
            >
              <div class="row sandwich-product-list-page-row">
                <div
                  v-for="(sandwich, index) in sandwiches"
                  :key="index"
                  class="col-12 col-sm-6 col-lg-4 col-xl-3 sandwich-product-list-page-content"
                >
                  <product
                    :product-detail="sandwich"
                  />
                </div>
              </div>
            </div>
          </template>
        </product-tabs>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  const ProductTabs = () => import('@/components/product/ProductTabs')
  const Product = () => import('@/components/product/Product')

  export default {
    name: "ProductList",

    components: {
      ProductTabs,
      Product
    },

    data () {
      const snackTitle  = this.$i18n.t('Product.SNACKS')
      const sandwichTitle = this.$i18n.t('Product.SANDWICHES')

      return {
        tabs: [
          {
            isCartItem: false,
            containerName: 'snacks',
            title: snackTitle,
            isActive: true
          },
          {
            isCartItem: false,
            containerName: 'sandwich',
            title: sandwichTitle
          },
          {
            isCartItem: true
          }
        ],
        snacks: [],
        sandwiches: []
      }
    },

    computed: {
      ...mapGetters({ settingsId: 'getSettingId' })
    },

    mounted () {
      this.loadProducts()
    },

    methods: {
      loadProducts () {
        this.$root.$load(true)
        const settingsId = this.settingsId
        this.$http.post(`get-snack-list/${settingsId}`)
          .then((response) => {
            if (response.status === 200) {
              const items = response.data.data
              this.snacks = items.filter((snack) => { return snack.type === 'snack' })
              this.sandwiches = items.filter((sandwich) => { return sandwich.type === 'sandwich' })
            }
          })
          .catch((e) => { console.log(e) })
          .finally(() => { this.$root.$load(false) })
      }
    }
  }
</script>
